import FooterEnum from '../enumerators/footer.enum'

export default class FooterComponent {
    constructor() {
        FooterComponent.map()
    }

    static map() {
        $(FooterEnum.FOOTER_TRIGGER_MAP).on('click', function () {
            $(FooterEnum.FOOTER_TRIGGER_MAP).removeClass('is-active')
            $(this).addClass('is-active')

            const targetClass = $(this).hasClass('footer__title--france')
                ? 'footer__content__maps__map--france'
                : 'footer__content__maps__map--azur'
            $('.footer__content__maps__map').removeClass('is-active').fadeOut(500)
            $(`.${targetClass}`).addClass('is-active')
        })
    }
}
