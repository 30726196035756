import FooterComponent from '../components/footer.component'

export default class FooterService {
    constructor() {
        // Banner
        if (!IRISCollectionCustomer.isEcomodeActive) {
            new FooterComponent()
        }
    }
}
