import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.submenu()
        NavbarComponent.langsSwitcher()
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        $(window).on('load scroll', function () {
            const offset = 100 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()
            let hasBanner = false

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                hasBanner = true
            }

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
                $(NavbarEnum.BODY).removeClass('hp-header-animated')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $(NavbarEnum.LOGO)
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        //Open/Close menu
        $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
            $(NavbarEnum.BODY).toggleClass('menu-open')

            if (
                window.matchMedia('(max-width: 999px)').matches &&
                window.matchMedia('(min-width: 650px)').matches
            ) {
                if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                    NavbarComponent.changeLogoColor('banner')
                } else {
                    if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                        NavbarComponent.changeLogoColor('banner')
                    } else {
                        NavbarComponent.changeLogoColor('classic')
                    }
                }
            }

            //Change logo on mobile
            if (window.matchMedia('(max-width: 650px)').matches) {
                if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                    NavbarComponent.changeLogoColor('classic')
                } else {
                    if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                        NavbarComponent.changeLogoColor('banner')
                    }
                }
            }

            //Hide ecomode
            $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')
        })
    }

    static submenu() {
        const menuItemLink = $('[data-trigger="burger-submenu-toggle"]')
        const subMenuItem = $('[data-element="burger-submenu"]')

        // Open/close submenu
        if (window.matchMedia('(max-width: 1001px)').matches) {
            menuItemLink.on('click', function (e) {
                const targetItem = $(this).data('element-menu-item')
                const submenuKey = $(`[data-submenu-key="${targetItem}"`)

                // first click (open submenu)
                menuItemLink.removeClass('submenu-open')
                $(this).toggleClass('submenu-open')

                subMenuItem.removeClass('submenu-open')
                submenuKey.toggleClass('submenu-open')
                $(NavbarEnum.BODY).addClass('submenu-open')

                return false
            })
        }

        $(NavbarEnum.BURGER_MENU_PREV).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('submenu-open')
            subMenuItem.removeClass('submenu-open')
            menuItemLink.removeClass('submenu-open')
        })

        $(NavbarEnum.BURGER_MENU_CLOSE).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('menu-open')
            subMenuItem.removeClass('submenu-open')
            menuItemLink.removeClass('submenu-open')

            if ($('.navbar--onscroll').length) {
                NavbarComponent.changeLogoColor('classic')
            }

            if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                NavbarComponent.changeLogoColor('banner')
            } else {
                NavbarComponent.changeLogoColor('classic')
            }
        })
    }

    static langsSwitcher() {
        $(NavbarEnum.LANG_SWICTH_ELEMENT).hide()

        $(NavbarEnum.LANG_SWICTH_TRIGGER).on('click', () => {
            $(NavbarEnum.LANG_SWICTH_ELEMENT).toggleClass('open')
            $(NavbarEnum.LANG_SWICTH_ELEMENT).hasClass('open')
                ? $(NavbarEnum.LANG_SWICTH_ELEMENT).fadeIn(250)
                : $(NavbarEnum.LANG_SWICTH_ELEMENT).fadeOut(250)

            //Hide list lang on outside click
            if ($(NavbarEnum.LANG_SWICTH_ELEMENT).hasClass('open')) {
                $(window).click((e) => {
                    if (
                        $(e.target).attr('data-trigger') !== 'lang-switcher' &&
                        e.target.className !== 'current__lang'
                    ) {
                        $(NavbarEnum.LANG_SWICTH_ELEMENT).fadeOut(250)
                    }
                })
            }
        })
    }
}
